import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Newsletter from "../components/Newsletter";
import cover from "../images/spkl-logo-color.png";
import cow from "../images/cow-large.jpg";
import cowpart from "../images/cow-parts.png";
import Layout from "../layouts/index";
import SEO from "../components/SEO";
import Fade from "react-reveal/Fade";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Waypoint from "react-waypoint";
import { Link } from "gatsby";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    };
  }
  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    return (
      <Layout bodyClass="home">
        <SEO
          title="Australian Grown Beef"
        />
        <div className="first-section">
          <div className="container">
            <div className="contents" style={{ opacity: 0 }}>
              <Fade>
                <img
                  src={cover}
                  className="img-fluid hero-logo"
                  alt="SPKL logo"
                />
              </Fade>
            </div>
            <div>
              <AnchorLink
                href="#teasers"
                className="page-scroll scroll-down"
                style={{ display: "none" }}
              >
                <div className="" />
                <span className="">Discover More</span>
                <hr className="" />
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="wr-teasers" id="teasers">
          <section className="teaser section" id="teaser-1" name="teaser-1">
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="">
                    <Fade>
                      <h2 className="title-3 mt-3">
                        Cast your mind back to the most{" "}
                        <span className="accent">
                          tender, juiciest, flavoursome{" "}
                        </span>
                        beef you’ve ever experienced.
                      </h2>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="teaser section" id="teaser-2">
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="">
                    <Fade>
                      <h2 className="title-3">
                        <Fade delay={800}>
                          <span className="">Thinking of it? </span>
                        </Fade>
                        {/* <Fade delay={1200}>
                        <span className="">Good.</span></Fade>
                        <br /> */}
                        <Fade delay={1300}>
                          <div className="">Good. Now forget it.</div>
                        </Fade>
                      </h2>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="teaser section" id="teaser-3">
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="">
                    <Fade>
                      <h2 className="title-3">
                        <Fade delay={1000} bottom>
                          <span className="">
                            There’s no point hanging onto that memory…
                          </span>
                        </Fade>
                        <br />
                        <Fade delay={1200} bottom>
                          <span className="">it’s about to be replaced.</span>
                        </Fade>
                      </h2>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
            <AnchorLink href="#about" className="page-scroll scroll-down">
              <i className="lni-chevron-down" />
            </AnchorLink>
          </section>
        </div>
        <div className="container-fluid section-3">
          <div className="row">
            <div className="col-md-6 col-sm-12 w-clr">
              <div className="wrap-text">
                <div className="title-3">
                  Described by many a happy Aussie as the best steak they’ve
                  ever tried, SPKL is the ultimate combination of phenomenal
                  flavour, melt in your mouth tenderness, and stunning rich
                  marbling.
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 b-clr">
              <div className="wrap-text">
                <div className="title-6">
                  <div className="line-1">
                    {" "}
                    It’s simultaneously full of flavour yet incredibly delicate
                  </div>

                  <div className="bar" />
                  <div className="line-2">
                    It’s a premium dining experience like no other
                  </div>
                  <div className="bar bar1" />

                  <div className="line-3">
                    It’s the entire orchestra dialled up to eleven
                  </div>
                  <div className="bar bar2" />
                </div>
                <div className="title-3">
                  It’s beef like you’ve never tasted before
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="section-four">
            <div className="cover-container">
                <div className="cover-img">
                    <img alt="WAGWHO?" className="img-fluid" src={cow} />
                </div>
                <div className="cover-content">
                    <div className="wrap-hero">
                        <h1 className="title-4">
                            WAGWHO? THERE'S A NEW COW IN TOWN, 
                            ONCE YOU TRY IT YOU'LL FORGET THE REST
                        </h1>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-five">
                <div className="wrap">
                  <div className="content">
                    <div className="small-title">SPECKLE PARK CATTLE</div>
                    <div className="mid-txt">
                      More flavoursome than Angus and more delicate than Wagyu,
                      SPKL is the perfect beef for every dining experience.
                    </div>
                    <div className="part-img">
                      <Img
                        fluid={this.props.data.parts.childImageSharp.fluid}
                        className="img-fluid"
                        alt="cow parts"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Newsletter />
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query {
    parts: file(relativePath: { eq: "cow-parts.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
